import styled from 'styled-components'

import Section from '../../sections'

const AfterSection = styled(Section)`
  padding: 12px;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
`

const After = () => {
  return (
    <AfterSection>
      © Donamaid 2025. Todos os direitos reservados.
    </AfterSection>
  )
}

export default After
