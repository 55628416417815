import { useState } from 'preact/hooks';
import styled, { css } from 'styled-components';
import { Link } from 'preact-router/match'

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0;
  padding: 24px 0;
`

const Options = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  scroll-snap-type: x mandatory;
  color: #fff;
`

const Option = styled.div`
  width: fit-content;
  padding: 24px 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ selected }) => selected && css`
    font-weight: 500;
    border-bottom: solid 3px #fff;
  `};
`

const Links = styled.div`
  width: 100%;
  margin: 24px 0;
  padding: 24px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  border-top: solid 1px #fff;
  border-bottom: solid 1px #fff;

  a {
    text-decoration: none;
    color: #e2e2e2;
    font-size: 14px;
  }

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const PopularPages = () => {
  const [selectedCity, setSelectedCity] = useState('RS');

  return (
    <Root>
      <Options>
        <Option selected={selectedCity === 'RS'} onClick={() => setSelectedCity('RS')}>
          Rio Grande do Sul
        </Option>

        <Option selected={selectedCity === 'SP'} onClick={() => setSelectedCity('SP')}>
          São Paulo
        </Option>

        <Option selected={selectedCity === 'SC'} onClick={() => setSelectedCity('SC')}>
          Santa Catarina
        </Option>
  
        <Option selected={selectedCity === 'PR'} onClick={() => setSelectedCity('PR')}>
          Paraná
        </Option>

        <Option selected={selectedCity === 'MG'} onClick={() => setSelectedCity('MG')}>
          Minas Gerais
        </Option>

        <Option selected={selectedCity === 'GO'} onClick={() => setSelectedCity('GO')}>
          Goiânia
        </Option>
      </Options>

      <Links>
        {selectedCity === 'RS' && (
          <>
            <Link href='/cidades/canoas'>Diarista em Canoas</Link>
            <Link href='/cidades/caxias-do-sul'>Diarista em Caxias do Sul</Link>
            <Link href='/cidades/novo-hamburgo'>Diarista em Novo Hamburgo</Link>
            <Link href='/cidades/porto-alegre'>Diarista em Porto Alegre</Link>
            <Link href='/cidades/rio-grande'>Diarista em Rio Grande</Link>
            <Link href='/cidades/santa-maria'>Diarista em Santa Maria</Link>
          </>
        )}

        {selectedCity === 'SP' && (
          <>
            <Link href='/cidades/barueri'>Diarista em Barueri</Link>
            <Link href='/cidades/campinas'>Diarista em Campinas</Link>
            <Link href='/cidades/diadema'>Diarista em Diadema</Link>
            <Link href='/cidades/guarulhos'>Diarista em Guarulhos</Link>
            <Link href='/cidades/jundiai'>Diarista em Jundiaí</Link>
            <Link href='/cidades/limeira'>Diarista em Limeira</Link>
            <Link href='/cidades/mogi-das-cruzes'>Diarista em Mogi das Cruzes</Link>
            <Link href='/cidades/osasco'>Diarista em Osasco</Link>
            <Link href='/cidades/piracicaba'>Diarista em Piracicaba</Link>
            <Link href='/cidades/ribeirao-preto'>Diarista em Ribeirão Preto</Link>
            <Link href='/cidades/sao-bernardo-do-campo'>Diarista em São Bernardo do Campo</Link>
            <Link href='/cidades/sao-paulo'>Diarista em São Paulo</Link>
            <Link href='/cidades/sao-jose-dos-campos'>Diarista em São José dos Campos</Link>
            <Link href='/cidades/sao-jose-do-rio-preto'>Diarista em São José do Rio Preto</Link>
            <Link href='/cidades/santo-andre'>Diarista em Santo André</Link>
            <Link href='/cidades/sorocaba'>Diarista em Sorocaba</Link>
            <Link href='/cidades/suzano'>Diarista em Suzano</Link>
          </>
        )}

        {selectedCity === 'SC' && (
          <>
            <Link href='/cidades/joinville'>Diarista em Joinville</Link>
            <Link href='/cidades/camboriu'>Diarista em Balneário Camboriú</Link>
            <Link href='/cidades/blumenau'>Diarista em Blumenau</Link>
            <Link href='/cidades/camboriu'>Diarista em Camboriú</Link>
            <Link href='/cidades/chapeco'>Diarista em Chapecó</Link>
            <Link href='/cidades/florianopolis'>Diarista em Florianópolis</Link>
            <Link href='/cidades/itajai'>Diarista em Itajaí</Link>
            <Link href='/cidades/sao-jose'>Diarista em São José</Link>
          </>
        )}

        {selectedCity === 'PR' && (
          <>
            <Link href='/cidades/colombo'>Diarista em Colombo</Link>
            <Link href='/cidades/curitiba'>Diarista em Curitiba</Link>
            <Link href='/cidades/londrina'>Diarista em Londrina</Link>
            <Link href='/cidades/maringa'>Diarista em Maringá</Link>
            <Link href='/cidades/cascavel'>Diarista em Cascavel</Link>
            <Link href='/cidades/foz-do-iguacu'>Diarista em Foz do Iguaçu</Link>
            <Link href='/cidades/ponta-grossa'>Diarista em Ponta Grossa</Link>
          </>
        )}

        {selectedCity === 'MG' && (
          <>
            <Link href='/cidades/belo-horizonte'>Diarista em Belo Horizonte</Link>
            <Link href='/cidades/betim'>Diarista em Betim</Link>
            <Link href='/cidades/contagem'>Diarista em Contagem</Link>
            <Link href='/cidades/governador-valadares'>Diarista em Governador Valadares</Link>
            <Link href='/cidades/juiz-de-fora'>Diarista em Juiz de Fora</Link>
            <Link href='/cidades/montes-claros'>Diarista em Montes Claros</Link>
            <Link href='/cidades/ribeirao-das-neves'>Diarista em Ribeirão das Neves</Link>
            <Link href='/cidades/uberaba'>Diarista em Uberaba</Link>
            <Link href='/cidades/uberlandia'>Diarista em Uberlândia</Link>
          </>
        )}

        {selectedCity === 'GO' && (
          <>
            <Link href='/cidades/aparecida-de-goiania'>Diarista em Aparecida de Goiânia</Link>
            <Link href='/cidades/goiania'>Diarista em Goiânia</Link>
          </>
        )}
      </Links>
    </Root>
  );
};

export default PopularPages;
